import axios from 'axios'
import FormData from 'form-data'

import { getHostName } from '../utils'

import { adminEmailEndpoint, loginEndpoint, sendEmailEndpoint, sendFNOLDataEndpoint, websiteMaintenanceEndpoint, policyChangeEndpoint, holidayPopupEndpoint, webMessageEndpoint, docUploadEndpoint } from './apiConfig'

const headers = {
  "Content-Type": "application/json",
  "Authorization": ""
};

const domainName = (location) => {
  // console.log(location);
  var name = {
    'dev.cure.com': 'Staging',
    'localhost': 'Staging',
    'staging.cure.com': 'Staging',
    'prepublish.cure.com': 'Prepublish',
    'www.cure.com': 'Production',
    'gw.cure.com': 'Production'
  }
  var domain = name[location];
  // console.log(domain);
  return domain;
}

export const verifyLogin = async (username, password, type) => {
  const { method, url } = loginEndpoint.verify
  var userAgentString = navigator.userAgent

  var regex = /\((.*?)\)/;

  var match = userAgentString.match(regex);

  var device = match ? match[1] : null;

  const data =
    type === 'login'
      ? JSON.stringify({
        un: username,
        pw: password,
        device,
        Env: getHostName()
      })
      : JSON.stringify({
        zipcode: username,
        phone_ssn: password,
        device,
        Env: getHostName()
      })

  const config = {
    method,
    url,
    headers,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const checkSelfService = async (policyNumber, env) => {
  const { method, url } = loginEndpoint.checkSelfService

  const data =
    JSON.stringify({
      policyNumber: policyNumber,
      env: env ? env : getHostName()
    })

  const config = {
    method,
    url,
    headers,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const isUsernameRegistered = async ({ PolicyNumber, ZipCode, Username }) => {
  const { method, url } = loginEndpoint.isUsernameRegistered
  const data = JSON.stringify({ PolicyNumber, ZipCode, Username })
  const config = {
    method,
    url,
    headers,
    data
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getSecurityQuestions = async () => {
  const { method, url } = loginEndpoint.getSecurityQuestions

  const config = {
    method,
    url,
    headers
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const registerAccount = async user => {
  const { method, url } = loginEndpoint.registerAccount
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const checkSecurityQuestions = async user => {
  const { method, url } = loginEndpoint.checkSecurityQuestions
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
    headers.Authorization = "Bearer " + apiResponse.data.Tkn;
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const retrievePolicyUser = async (LastName, DOB, ZipCode) => {
  const { method, url } = loginEndpoint.retrivePolicyUser
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({
      LastName,
      DOB,
      ZipCode
    })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const checkGWAccount = async ({ LastName = null, FirstName = null, DOB = null, ZipCode = null, EmailAddress = null, PolicyNumber = null, GWUserID = null }) => {
  const { method, url } = loginEndpoint.checkGWAccount
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({
      LastName,
      FirstName,
      DOB,
      ZipCode,
      EmailAddress,
      PolicyNumber,
      GWUserID
    })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const updateEmail = async user => {
  const { method, url } = loginEndpoint.updateEmail
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getZip = async user => {
  const { method, url } = loginEndpoint.getZip
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const updatePassword = async user => {
  const { method, url } = loginEndpoint.updatePassword
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const processFeedback = async user => {
  const { method, url } = loginEndpoint.feedback
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getPolicyFromEmailUpdateToken = async (tokenObj) => {
  const { method, url, ...props } = loginEndpoint.getPolicyFromEmailUpdateToken;
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify(tokenObj),
  };

  var apiResponse;
  try {
    apiResponse = await axios(config);
    console.log("GetPolicyFromEmailUpdateToken", apiResponse);
  } catch (error) {
    console.error("GetPolicyFromEmailUpdateToken", error);
    return { error };
  }
  return apiResponse.data;
};

export const getUseGenesysWebMessaging = async (Env) => {
  const { method, url } = loginEndpoint.getUseGenesysWebMessaging
  const config = {
    method,
    url: url + "?Env=" + Env,
    headers,
  }
  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}

export const initiateAgentlessConversation = async (toAddress) => {
  const { method, url } = loginEndpoint.initiateAgentlessConversation
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ toAddress })
  }
  var apiResponse;
  try {
    apiResponse = await axios(config)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse.data
}


export const twilioRegistration = async (user) => {
  const { method, url } = policyChangeEndpoint.twilioRegistration

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  var apiResponse;
  try {
    apiResponse = await axios(config)
    console.log("TwilioRegistration", apiResponse)
  }
  catch (e) {
    console.log(e)
  }
  return apiResponse
}

export const resetPasswordWithTemp = async user => {
  const { method, url } = loginEndpoint.resetPassword
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...user })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const sendEmailForExpressPolicyChanges = async content => {
  const { method, url } = sendEmailEndpoint.expressPolicyChange
  let clonedContent = { ...content }

  if (getHostName() === 'uat') {
    clonedContent = {
      ...content,
      isTest: true
    }
  }

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...clonedContent })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getWebsiteMaintenanceStatus = async content => {
  const { method, url } = websiteMaintenanceEndpoint.status
  var Environment = domainName(window.location.hostname)

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getHolidayPopupStatus = async content => {
  const { method, url } = holidayPopupEndpoint.status
  var Environment = domainName(window.location.hostname)

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getWebMessageStatus = async content => {
  const { method, url } = webMessageEndpoint.status
  var Environment = domainName(window.location.hostname)

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const insertEmailUpdate = async content => {
  const { method, url } = loginEndpoint.insertEmailUpdate

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify(content)
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getWebsiteMaintenanceMessage = async content => {
  const { method, url } = websiteMaintenanceEndpoint.message
  var Environment = domainName(window.location.hostname)

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const getHolidayPopupMessage = async content => {
  const { method, url } = holidayPopupEndpoint.message
  var Environment = domainName(window.location.hostname)

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ Environment })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const unsubscribeEmail = async email => {
  const { method, url } = adminEmailEndpoint.unsubscribe(email)
  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ email })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const sendEmailForPIPDocumentUpload = async (filesList, htmlString, user) => {
  const { method, url } = sendEmailEndpoint.PIPDocumentUpload
  const {
    firstName,
    lastName,
    policyNumber,
    emailAddress,
    state,
    description
  } = user
  var formdata = new FormData();
  console.log(filesList);
  for (let i = 0; i < filesList.length; i++) {
    formdata.append("files", filesList[i])
  }

  var newFile = new File([htmlString], "htmlStringToSendWithFileNames");
  formdata.append("htmlStringToSendWithFileNames", newFile);
  formdata.append("firstName", firstName);
  formdata.append("lastName", lastName);
  formdata.append("policyNumber", policyNumber);
  formdata.append("emailAddress", emailAddress);
  formdata.append("state", state);
  formdata.append("description", description);

  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formdata
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const sendEmailForClaimsFNOL = async content => {
  const { method, url } = sendEmailEndpoint.claimFnol
  let clonedContent = { ...content }

  if (getHostName() === 'uat') {
    clonedContent = {
      ...content,
      isTest: true
    }
  }

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...clonedContent })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}

export const sendFNOLData = async content => {
  const { method, url } = sendFNOLDataEndpoint.sendFNOLData

  const config = {
    method,
    url,
    headers,
    data: JSON.stringify({ ...content })
  }

  let apiResponse
  try {
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}


export const getPrefillDataForDocUpload = async (tkn, userAgent = "") => {
  const { method, url } = docUploadEndpoint.getPrefillDataForDocUpload
  const data = {
    tkn: tkn,
    ua: userAgent
  }
  headers.Authorization = "Bearer " + tkn;
  const config = {
    method,
    url,
    headers,
    data
  }

  let apiResponse
  try {    
    apiResponse = await axios(config)
  } catch (error) {
    console.log(error)
  }
  return apiResponse
}